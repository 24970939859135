<template>
    <div class="ol-dashboard__order review-container">
    <LoadingImg v-if="loading" />
    <div class="review-card">
        <div>
            <h4 class="ol-dashboard__container-title">Ajukan Komplain Pesananmu</h4>
        </div>

        <div>
            <div class="ol-dashboard__order-address komplainOrderOverview">
                <div class="ol-dashboard__order-address-item">
                    <h3 class="ol-dashboard__order-address-item-title">Alamat Pengiriman</h3>
                    <h4 class="ol-dashboard__order-address-item-name">
                        {{ order.receiver_name }} - {{ order.r_more_transaction_order_details[0].receipt_number }}
                    </h4>
                    <p class="ol-dashboard__order-address-item-desc">
                        {{ order.receiver_phone }}<br>
                        {{ order.full_address }}
                    </p>
                </div>
                <div class="ol-dashboard__order-address-item ">
                    <h3 class="ol-dashboard__order-address-item-title">Total Belanja</h3>
                    <h4 class="ol-dashboard__order-address-item-name">
                        {{ order.total_rp }}
                        
                    </h4>
                    
                    </div>
                </div>
            </div>
            <form>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Alasan Komplain </label>
                    <select v-model="complain.reason" class="form-control" id="exampleFormControlSelect1">
                        <option value="" selected disabled>-- Pilih Opsi --</option>
                        <option v-for="template in complainTemplates" :key="template.id" :value="template.text">{{template.text}}</option>
                    </select>
                </div>
            </form>
        </div>
        <div>
            <div v-for="(item,i) in products" :key="item.id+'-product'"  :class="complainProducts[i].id ? 'productselected productselectedActive': 'productselected '">
                <label class="komplainProduct" :for="item.id+'-cb'">

                    <label class="komplainProductContainer">
                        <input class="checkKomplain" :id="item.id+'-cb'" type="checkbox" v-model="pickedIndex" :value="i" :checked="complainProducts[i].id ? 'checked':''">
                        <span class="checkmark"></span>
                    </label>

                    <div class="komplainImgContainer">
                        <img v-if="item.r_uni_product_variant.r_uni_product_image" :src="item.r_uni_product_variant.r_uni_product_image.image_url" :alt="item.r_uni_product_variant.name_long" :title="item.r_uni_product_variant.name_long" />
                        <img v-else :src="noImage()" :alt="item.r_uni_product_variant.name_long" :title="item.r_uni_product_variant.name_long" />
                    </div>

                    <div class="komplainDescContainer">
                        <div>
                            <h3 class="komplainProdukNama" style="padding: 0; margin: 0;">
                                <p>{{item.r_uni_product_variant.name_long}}</p>
                            </h3>
                            <span class="ol-cart-list__body-item-desc-type">Jumlah: {{ item.quantity }} </span>
                            <br>
                            <p class="ol-cart-list__body-item-desc-type">Harga: Rp.
                                {{ item.price_rp }}
                        </p></div>
                    </div>


                </label>
                <div :class="complainProducts[i].id ? 'komplainDetail collapse in':'komplainDetail collapse'">

                    <div class="inputKomplain">
                        <form>
                            <div class="form-group">
                                <textarea v-model="complainProducts[i].note" class="form-control" placeholder="Tolong beritahu kami alasan dari ajuan komplain terhadap produk yang kamu terima" rows="3"></textarea>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div class="uploadReview">
                            <label for="exampleFormControlInput1">Unggah Foto Bukti Produk</label>
                            <form class="dropzone" @submit.prevent="">
                                <input type="hidden" name="more_product_reviews_id">
                                <Vue2Dropzone ref="myVueDropzone" :id="'dz-'+i" :options="dropzoneOptions" :duplicateCheck="true" @vdropzone-removed-file="onRemovedFile" @vdropzone-sending-multiple="onSendingMultipleFile" @vdropzone-success-multiple="onSuccessMultipleFile"></Vue2Dropzone>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <router-link style="float:left;font-size: 13px;margin-top:20px;" :to="'/profile/order/'+order.ordersn" class="ol-btn ol-btn--blue">Detail Pesanan </router-link>
            <a class="ol-btn btn-review" @click="onComplain()">Kirim</a>
        </div>
    </div>
</template>

<script>
import Vue2Dropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'
import globalUrl from '@/util/globallUrl'
const URL = globalUrl.WS_PROFILE.PRODUCT;

export default {
    name:"ProfileOrderComplain",
    components:{
        Vue2Dropzone
    },
    data(){
        return {
            order: {},
            loading:false,
            products:[],
            complain:{},
            complainProducts:[],
            dropzoneOptions: {},
            alertMessage:false,
            dzOproductId:null,
            preUploadLength:0,
            dzSuccessCount:0,
            pickedIndex:[],
        }
    },
    computed:{
        ...mapGetters("profile/product",["complainTemplates"]),
        ...mapGetters('auth', ['accessToken']),
    },
    methods:{
        ...mapActions("profile/product",["fetchComplainTemplates","saveComplain","saveComplainImage"]),
        preComplainValid(){
            try {
                if(!this.complain.reason) {
                    this.$toast.open({ message: "Alasan Komplain wajib dilengkapi", type: "warning", duration: 5000, });
                    return false;
                }
                
                this.pickedIndex.forEach(index => {
                    let queuedFiles = this.$refs.myVueDropzone[index].getQueuedFiles();
                    if(!this.complainProducts[index].note){
                        this.$toast.open({ message: "Deskripsi komplain wajib dilengkapi pada produk yang dipilih (dikomplain). ", type: "warning", duration: 5000, });
                        throw 'Break';
                    }
                    
                    if(queuedFiles.length == 0){
                        this.$toast.open({ message: "Foto wajib dilampirkan pada produk yang dipilih (dikomplain). ", type: "warning", duration: 5000, });
                        throw 'Break';
                    }
                });

                return true;
            } catch (e) {
                return false;
            }
        },
        async onComplain(){
            if(this.preComplainValid()){
                this.complain.r_more_complain_products = _.filter(this.complainProducts,(q) => q.note);

                this.alertMessage = false;
                this.loading = true;
                let result = await this.saveComplain(this.complain);
                if(result.success){
                    this.complain = result.data;
                    this.doUpload();
                }else{
                    this.loading = false;
                }
            }
        },
        
        async doUpload(){
            let products = this.products;
            let preUpload = [];
            await this.complain.r_more_complain_products.forEach(item => {
                let index = _.findIndex(products,{id: parseInt(item.more_transaction_order_products_id)});
                let queuedFiles = this.$refs.myVueDropzone[index].getQueuedFiles();
                if(queuedFiles.length > 0){
                    this.dzOproductId = parseInt(item.id);
                    this.$refs.myVueDropzone[index].processQueue();
                    preUpload.push({
                        more_complain_products_id : parseInt(item.id),
                        file: queuedFiles,
                    });
                }
            });
            this.preUploadLength = preUpload.length;
            if(this.preUploadLength == 0){
                this.loading = false;
                this.$router.push('/profile/order/'+this.order.ordersn);
            }
        },
        onSendingMultipleFile(file, xhr, formData) {
            formData.append('more_complain_products_id', this.dzOproductId);
        },
        onSuccessMultipleFile(file, response){
            this.complain = response.data;
            this.dzSuccessCount++;
            if( (this.preUploadLength > 0) && this.preUploadLength == this.dzSuccessCount){
                this.preUploadLength = 0;
                this.dzSuccessCount = 0;
                
                // this.syncDetailOrder();
                this.loading = false;
                this.$router.push('/profile/order/'+this.order.ordersn);
            }
        },
        
        dzLoadFiles(){
            // console.log("this.complainProducts",this.complainProducts);
            for (let i = 0; i < this.complainProducts.length; i++) {
                // this.$refs.myVueDropzone[i].removeFile();
                this.complainProducts[i].r_more_complain_product_images.forEach(image => {
                    let file = { complain_image_id:image.id, name: image.name, type: image.mime_type, size:image.image_size };
                    let url = image.image_url;
                    this.$refs.myVueDropzone[i].manuallyAddFile(file, url);
                });
            }
        },
        init(){
            // this.dzLoadFiles();

            $(".checkKomplain").on("click", function () {
                let ba = $(this).parent().parent().siblings();
                let parpar = $(this).parent().parent().parent();
                if (this.checked == true) {
                    ba.addClass("in");
                    parpar.addClass("productselectedActive");
                } else {
                    ba.removeClass("in");
                    parpar.removeClass("productselectedActive");
                }

            })
        }
    },
    created(){
        this.fetchComplainTemplates();
        this.order = JSON.parse(localStorage.getItem("detailOrder")).order;
        this.products = this.order.r_more_transaction_order_details[0].r_more_transaction_order_products;
        
        if(this.order.r_more_complain){
            this.complain = this.order.r_more_complain;
        }else{
            this.complain = {
                id: null,
                more_transaction_orders_id: this.order.id,
                reason: null,
                solution: null,
                r_more_complain_products:[],
            }
        }


        this.complainProducts = _.map(this.products,(q) => {
            return {
                id: q.r_more_complain_products ? q.r_more_complain_products.id : null,
                more_transaction_order_products_id: q.id,
                more_complains_id: q.r_more_complain_products ? q.r_more_complain_products.more_complains_id : null,
                note: q.r_more_complain_products ? q.r_more_complain_products.note : "",
                r_more_complain_product_images: q.r_more_complain_products ? q.r_more_complain_products.r_more_complain_product_images : [],
            };
        });
        
        this.dropzoneOptions = {
            url: URL._COMPLAIN_IMAGE_UPLOAD,
            autoProcessQueue: false,
            uploadMultiple:true,
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 10,
            parallelUploads: 10,
            addRemoveLinks: true,
            headers: { "Authorization":'Bearer ' + this.accessToken },
            dictDefaultMessage:"<i style='color:grey;' class='fas fa-camera fa-lg'></i>",
            dictRemoveFile:"Hapus file",
        };
    },
    mounted(){
        this.init();
    }
}
</script>

<style lang="css" scoped>
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/js/dropzone/dropzone.css';
    @import '../../assets/css/ulasan.css';
</style>